import type { ILeaseExportGroup } from '@export'
import type { TransferListOptionsItem } from '@reports/components/TransferList/types'
import type { Ref } from 'vue'

export function useTransferList(
  divisionId: string,
  activeGroup: Ref<ILeaseExportGroup | undefined>,
  modelValue: Ref<string[]>,
) {
  /* === OPTIONS === */
  // All options
  const { data } = useApiExportFields(divisionId)

  const fields = computed(() =>
    (data.value ?? []).flatMap((group) => group.fields ?? []),
  )

  const allOptions = computed<TransferListOptionsItem[]>(() => {
    return fields.value.map((field) => ({
      label: field.name!,
      value: field.id!,
    }))
  })
  if (fields.value.length === 0) {
    // If fields are still fetching, save initial value to set on success
    const initialValue = modelValue.value
    watchOnce(fields, () => {
      selections.value = toOptions(initialValue)
    })
  }

  // Selected options (object)
  const selections = ref<TransferListOptionsItem[]>(toOptions(modelValue.value))
  syncRef(selections, modelValue, {
    deep: true,
    transform: {
      ltr: toValues,
      rtl: toOptions,
    },
  })

  // Only options from active group
  const activeOptions = computed<TransferListOptionsItem[]>(() =>
    (activeGroup.value?.fields ?? []).map((field) => ({
      label: field.name!,
      value: field.id!,
    })),
  )
  // Only options that are selected from the active group
  const selectedOptions = computed<TransferListOptionsItem[]>(() =>
    activeOptions.value.filter((option) =>
      selections.value.find((o) => o.value === option.value),
    ),
  )
  // Only options that are not selected from the active group
  const unselectedOptions = computed<TransferListOptionsItem[]>(() =>
    activeOptions.value.filter(
      (option) => !selections.value.find((o) => o.value === option.value),
    ),
  )
  /* === OPTIONS === */

  /* === SELECT === */
  const select = (option: TransferListOptionsItem) => {
    selections.value.push(option)
  }
  const selectAll = () => {
    selections.value = [...selections.value, ...activeOptions.value].filter(
      (option, index, self) =>
        index === self.findIndex((o) => o.value === option.value),
    )
  }
  /* === SELECT === */

  /* === UNSELECT ===*/
  const unselect = (option: TransferListOptionsItem) => {
    selections.value = selections.value.filter((o) => o.value !== option.value)
  }
  const unselectAll = () => {
    // Unselect all from options
    selections.value = selections.value.filter(
      (option) => !activeOptions.value.find((o) => o.value === option.value),
    )
  }
  /* === UNSELECT ===*/

  return {
    selectedOptions,
    unselectedOptions,
    selections,
    modelValue,
    select,
    selectAll,
    unselect,
    unselectAll,
  }

  function toValues(options: TransferListOptionsItem[]) {
    return options.map((option) => option.value)
  }

  function toOptions(values: string[]) {
    return values
      .map((v) => {
        return allOptions.value.find((o) => o.value === v)
      })
      .filter(Boolean) as TransferListOptionsItem[]
  }
}
