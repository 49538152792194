import type { ILeaseExportGroup } from '@export'
import type { TransferListOptionsItem } from '@reports/components/TransferList/types'

export function useGroups(divisionId: string) {
  const { data } = useApiExportFields(divisionId)
  const groups = computed(() => data.value ?? [])
  const active = ref<ILeaseExportGroup>()

  // Select first group on fetch
  watch(
    groups,
    () => {
      if (!active.value) {
        active.value = groups.value[0]
      }
    },
    { deep: true, immediate: true },
  )

  const options = computed<TransferListOptionsItem[]>(() =>
    groups.value.map((group) => ({
      label: group.name!,
      value: group.id!,
    })),
  )
  const select = (option: TransferListOptionsItem) => {
    active.value = groups.value.find((group) => group.id === option.value)
  }

  return {
    options,
    active,
    select,
  }
}
